<template>
  <q-input
    filled
    disable
    v-if="showField"
    :label="field.label"
    :mask="field.mask"
    :model-value="field.value"
  >
    <template v-slot:prepend>
      <q-icon :name="field.icon" />
    </template>
    <template v-slot:after v-if="$q.screen.width > 800">
      <q-btn
        label="Alterar"
        color="white"
        class="bg-black q-pa-md q-ml-sm"
        @click="showModal = true"
      />
    </template>
  </q-input>
  <div v-if="$q.screen.width <= 800" align="right" class="q-mt-md">
    <q-btn
      label="Alterar"
      color="white"
      class="bg-black q-pa-md q-ml-sm"
      @click="showModal = true"
    />
  </div>

  <q-dialog
    transition-show="slide-up"
    v-model="showModal"
    transition-hide="slide-down"
    persistent
  >
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('Atualizar ' + field.type)"
          />
          <q-space />
          <q-btn
            flat
            round
            dense
            icon="close"
            color="red"
            v-close-popup
            @click="clearData()"
          />
        </q-card-section>
        <q-separator class="q-mr-md q-ml-md" />
        <div>
          <q-form @submit="submit()" greedy>
            <q-card-section class="q-pt-none q-mt-md">
              <div
                class="bg-grey-4 q-pa-md text-justify q-mb-md"
                style="border-radius: 5px"
                v-html="
                  $t(`account.validate_or_change.${field.type}.label_step_1`)
                "
              />

              <q-input
                filled
                v-model="newValue"
                :mask="field.mask"
                :placeholder="field.placeholder"
                :type="field.type == 'email' ? 'email' : 'text'"
                :rules="[(v) => !!v || $t('validations.required')]"
                lazy-rules="ondemand"
              >
                <template v-slot:prepend>
                  <q-icon :name="field.icon" />
                </template>
              </q-input>
            </q-card-section>
            <q-card-section class="q-pt-none" v-if="!isValid">
              <div
                class="bg-red-2 q-pa-md text-justify q-mb-md"
                style="border-radius: 5px"
                v-html="$t(errorMessage)"
              />
            </q-card-section>
            <q-card-actions align="center">
              <q-btn
                unelevated
                color="dark"
                type="submit"
                :loading="in_request"
                :label="$t('global.confirm')"
                class="text-weight-regular"
              />
            </q-card-actions>
          </q-form>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifySuccess } from "@/shared/helpers/notify";
import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useActions } = createNamespacedHelpers("account");
import { defineComponent, ref } from "vue";

import { validateEmail, validateCelular } from "./setup";

export default defineComponent({
  name: "FieldToUpdateComponent",

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  emits: ["verifyOrChange"],

  setup(props) {
    const showModal = ref(false);
    const in_request = ref(false);
    const isValid = ref(true);
    const newValue = ref("");
    const errorMessage = ref("");
    const showField = ref(true);

    const { updateUserPartnerAction, fetchPartnerChallenges } = useActions([
      "updateUserPartnerAction",
      "fetchPartnerChallenges",
    ]);

    const submit = () => {
      (props.field.type == "email"
        ? validateEmail(newValue.value)
        : validateCelular(newValue.value)
      )
        .then(() => {
          isValid.value = true;
          in_request.value = true;
          updateUserPartnerAction(
            props.field.type == "email"
              ? { email: newValue.value }
              : { celular: newValue.value }
          )
            .then(() => {
              showField.value = false;
              fetchPartnerChallenges()
                .then(() => {
                  notifySuccess("Dados atualizados com sucesso!");
                  showModal.value = false;
                  clearData();
                })
                .finally(() => (showField.value = true));
            })
            .finally(() => (in_request.value = false));
        })
        .catch(({ errors }) => {
          alert(props.field.type);
          errorMessage.value =
            props.field.type == "email" ? errors.email[0] : errors.celular[0];

          isValid.value = false;
        });
    };

    const clearData = () => {
      newValue.value = "";
      errorMessage.value = "";
    };

    return {
      in_request,
      showModal,
      newValue,
      showField,
      errorMessage,
      isValid,
      submit,
      clearData,
    };
  },
});
</script>
